const ROLE_TYPE = {
  CUSTOMER_ADMIN: 'customer-admin',
  CUSTOMER_AUTHORIZER: 'customer-authorizer',
  CUSTOMER_PURCHASER: 'customer-purchaser',
  CUSTOMER_OBSERVER: 'customer-observer',
  CUSTOMER_CATALOG_ADMIN: 'customer-catalog-admin',
  VENDOR_ADMIN: 'vendor-admin',
  SUPER_ADMIN: 'super-admin',
  SUPER_DUPER_ADMIN: 'super-duper-admin'
};

export default Object.freeze(ROLE_TYPE);
